.containerLinks {
    position: relative;
}

.menu-item {
    display: inline-block;
    position: relative;
    padding: 10px 20px;
    color: var(--text-color2);
    border-radius: 5px;
    margin: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.menu-item:hover {
    color: var(--color1);
}

.submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--blanco);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    width: 60vh;
    max-height: 60vh;
    overflow-y: auto;
}
.submenu::-webkit-scrollbar{
    width: 6px;
    height: 5px;
 
}
 
.submenu::-webkit-scrollbar-thumb {
    background-color: var(--color1); 
    border-radius: 10px;
    margin-top: 3rem;
  }

.menu-item:hover .submenu {
    display: block;
}

.submenu a {
    color: var(--text-color2);
    padding: 5px 10px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
    display:flex ;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    transition: all 0.2s linear;
    margin-bottom: 10px;
}

.submenu a:hover {
    background-color: var(--color1);
    color: var(--blanco);
    transition: all 0.2s linear;
}

.submenu a img{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}
.categoria-container h6{
    color: var(--text-color2);
    font-size: 16px;
    font-weight: 500;
    padding: 20px 10px 7px;
}

.submenu hr{
    margin-bottom: 10px;
    margin-left: 10px;
}

/* Estilos para el icono de + en pantallas pequeñas */
.toggle-button {
    display: none;
    bottom: 20px;
    right: 20px;
    background-color: var(--color1);
    color: var(--blanco);
    border: none;
    border-radius: 50%;
    font-size: 22px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
}



.toggle-button:hover {
    background-color: var(--color2);
}
/* Estilos para el modal */
.modalLinks {
    
   display: none;
}

.overlayInput {
    background-color: rgba(0, 0, 0, 0.5);
}

/* Estilos para los botones de navegación en el modal */
.modal-flexButons {
   display: flex;
   justify-content: space-between;
   border-radius: 10rem;
   margin: 10px 10px 20px;
   background-color: var(--gris2);
}
.modal-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 10px ;
    width: 100%;
    border-radius: 10rem;
  
}
.modal-button.active {
    color: var(--blanco)!important;
    background-color: var(--color1);
}


.deColumnTe{
    display: flex;
    flex-direction: column;
}



/* Media Query para pantallas con ancho de 900px o menor */
@media (max-width: 900px) {
    .menu-item {
        display: none;
    }

    .toggle-button {
        display: block;
    }
    .submenu {
        display: flex;
        flex-direction: column;
        position: relative;
        top: auto;
        left: 0;
        background-color: var(--blanco);
        padding: 0 10px;
        border-radius: 5px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0);
        width: 100%;
        max-height: 75vh;
        overflow-y: auto;
        margin-top: -1rem;
    }
    .modalLinks {
        width: 100%;
        height: 100vh;
        background-color: var(--blanco);
        outline: none;
        display: flex;
        flex-direction: column;
        border: none;
        
    }

    .modal-header{
        background-color: var(--color1);
        width: 100%;
        padding: 10px 20px;
    }
    .modal-header button{
        background-color: transparent;
        border: none;
        color: var(--blanco);
        font-size: 17px;
    }
}
