.TiendaContainDetail{
    padding: 0 10% 3rem ;
}
.bannerTienda{
    display: flex;
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
   
}
.bannerTienda img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}
.deFlexTiendaDetail{
    display: flex;
    justify-content: space-between;
    padding: 30px 20px;
    align-items: center;
    gap: 2rem;
    position: absolute;
    width: 100%;
}

.tiktok{
    border-radius: none!important;
    box-shadow: none!important;
    width: 20px;
}
.cardTextTiendaDetail{
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.068);
    background-color: var(--blanco);
    margin-top: -2rem;
    border-radius: 0 0 20px 20px;
}

.cardTextTiendaDetail img{
    width: 120px;
    height: 120px;
    border-radius: 100%;
    z-index: 2;
    margin-top: -3rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
    background-color: var(--blanco);
    border: 3px solid var(--blanco);
    cursor: pointer;
}
.cardTextTiendaDetail h2{
    font-weight: 600;
    font-size: 30px;
    color: var(--text-color2);
}
.cardTextTiendaDetail a{
    font-weight: 400;
    color: var(--text-color2);
}
.deFlexCardTienda{
    display: flex;
    gap: 1rem;
}
.deColumnCardTienda{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.deColumnCardTienda hr{
    width: 1px;
    height: 50px;
}

.shares{
    background-color: transparent;
    border: none;
    border: none;
    font-size: 17px;
    color: var(--text-color2);
    border-radius: 100%;
    cursor: pointer;
}
.cardTextTiendaDetailText{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 2rem;
    width: 100%;
}
.deFlexCardTextTiendaDetailText{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 3rem;
}
.cardTextTiendaDetailText h3{
    background-color: var(--color1);
    color: var(--blanco);
    padding: 3px 20px;
    border-radius: 10rem;
    font-weight: 500;
    text-align: center;
}
@media (max-width: 700px){
    .bannerTienda{
        height: 160px;
        padding: 0px 3%;
        border-radius: 20px;
        
}

.bannerTienda img{
    border-radius: 20px;
   
}
    .deFlexTiendaDetail{
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;
        align-items: center;
        gap: 2rem;
        position: absolute;
        width: 100%;
    }
    .cardTextTiendaDetail img{
        width: 80px;
        height: 80px;
        border-radius: 100%;
        z-index: 2;
        margin-top: -3rem;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
        background-color: var(--blanco);
    }

    .cardTextTiendaDetail{
    
        padding: 20px;
        margin-top: -1rem;
        gap: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
       
    }
    .deFlexCardTienda{
        padding: 20px;
        margin-top: -1rem;
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    .deColumnCardTienda hr{
        display: none;
    }
    .TiendaContainDetail{
        padding: 1rem 0 0;
        border-radius: 0;
}
.cardTextTiendaDetail h2{
    padding-top: 0;
    font-size: 20px;
}
.cardTextTiendaDetail span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 27ch;
    font-weight: 400;
    font-size: 13px;
    color: var(--text-color2);
}

.cardTextTiendaDetail .socials{
    padding-left: 0;
    padding-top: 0;
}

.deFlexCardTextTiendaDetailText h3{
    font-size: 16px;
    position: absolute;
    top: 80px;
    z-index: 1;
    border-radius: 7px;
   
}
.deFlexCardTextTiendaDetailText{
    width: auto;
    place-content: center;
}
.cardTextTiendaDetailText{
   align-items: center;
   justify-content: center;
   text-align: center;
   padding: 0;
}

}