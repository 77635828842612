
.modalAuthHome {
    top: 0;
    background-color: var(--blanco);
    width: 28%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: none;
    border-radius: 10px;
    
}



.overlayAuthHome {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.333); 
    z-index: 999; 
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1024px){
    
.modalAuthHome {
    width: 90%;
    
}
}